import React, {useEffect, useState} from 'react';
import { useLanguageStore } from "../hooks/useLanguage";
import Modal from 'react-bootstrap/Modal';
import {FaTimes, FaSave, FaPlus} from "react-icons/fa";
import toast from "react-hot-toast";

export const RealModal = (props) => {
  const { t } = useLanguageStore();
  const {
    show,
    onShow,
    onHide,
    theme,
    independentFunds,
    getIndependentFunds,
    selectedAccount,
    selectedIndependentFund,
    updateIndependentFund,
    independentFundToEdit,
    setIndependentFundToEdit,
  } = props;

  useEffect(() => {
    setIndependentFundToEdit(selectedIndependentFund);
  }, [selectedIndependentFund]);

  const saveFund = () => {
    if (independentFundToEdit?.name === '') {
      toast.error(t('Please enter a name'));
      return;
    }
    updateIndependentFund(independentFundToEdit).then(data => {
      if(data?.success) {
        toast.success(t('Fund updated successfully'));
        getIndependentFunds(selectedAccount);
        onHide();
      }else{
        toast.error(t('Failed to update fund'));
      }
    }).catch(err => {
      toast.error(t('Failed to update fund'));
    });
  }

  const fundNameChanged = (e) => {
    setIndependentFundToEdit({...independentFundToEdit, name: e.target.value});
  }

  return (
    <>
      <Modal.Body className={`box-shadow-${theme} rounded-3 p-0`}>
        <div className="row px-3 pt-3">
          <div className="d-flex col-lg-12">
            <h3 className={"mb-0"}>
              {t("Edit Independent Fund")}
            </h3>
            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
          </div>
        </div>

        <div className="pt-0 row px-3 pb-3">
          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="name">{t("Name")}</label>
              <input onInput={fundNameChanged} value={independentFundToEdit?.name} type="text" className="form-control" id="name"
                     placeholder={t("Enter Name")}/>
            </div>
          </div>
          <div className="col-12 mt-2">
            <button className="btn btn-primary w-100" onClick={saveFund}>
              <FaSave className={"me-1"}/>
              {t("Save")}
            </button>
          </div>
        </div>


      </Modal.Body>

    </>
  );
}

export const EditIndependentFundModal = (props) => {
  let {
    show,
    onHide,
    theme,
  } = props;
  return (
    <Modal
      size={'md'}
      show={show}
      onHide={onHide}
      className={`modal-custom-backdrop-${theme}`}
      backdrop={"static"}
    >
      {show && (
        <RealModal {...props}/>
      )}
    </Modal>
  )
}