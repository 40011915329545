import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import "./Styles/BankStatement.css";
import {getCookie} from "../func/Func";
import {useAuth} from "../hooks/useAuth";
import {useTheme} from "../hooks/useTheme";
import {usePermissions} from "../hooks/PermissionContext";
import toast from "react-hot-toast";
import Papa from "papaparse";
import {Button} from "@progress/kendo-react-buttons";
import {FaCheck} from "react-icons/fa";
import {Input} from "@progress/kendo-react-inputs";
import {Upload} from "@progress/kendo-react-upload";
import BankStatementUpload from "../components/BankStatementUpload";
import Dropdown from "react-bootstrap/Dropdown";
import {Form, Field, FormElement, FieldWrapper} from '@progress/kendo-react-form';
import {ifElse} from "@progress/kendo-data-query/dist/npm/funcs";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Grid, GridColumn} from "@progress/kendo-react-grid";


export const BankStatement = (props) => {
    const {permissions} = usePermissions();
    // const {theme, toggleTheme} = useTheme();
    const {user} = useAuth();
    const {theme} = useTheme();
    // const {user, fetchPermissions} = useAuth();
    const {t} = useTranslation();
    const [files, setFiles] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [columnArray, setColumnArray] = React.useState([]);
    const [values, setValues] = React.useState([]);
    const [check, setCheck] = React.useState(true);
    const [modifiedRows, setModifiedRows] = React.useState([]);
    console.log("modifiedRows", modifiedRows);
    const [allocations, setAllocations] = React.useState({});
    const [file, setfile] = React.useState(null);
    const [bankStatementDetails, setBankStatementDetails] = React.useState({});
    const [bankStatementHistoryUploaded, setBankStatementHistoryUploaded] = React.useState([]);
    const [selectedBankStatement, setSelectedBankStatement] = React.useState(null);
    const [accounts, setAccounts] = React.useState([]);


    console.log("modifiedRows", modifiedRows);
    console.log("files in bankstatement", files);
    console.log("permissions in bankstatement", permissions?.permissions);

    React.useEffect(() => {
        getAccounts();
        if (bankStatementHistoryUploaded.length > 0) {
            // Extract column names from the first object in the array
            const columns = Object.keys(bankStatementHistoryUploaded[0])
                .filter(
                    column => (column !== "id" &&
                        column !== "bank_account" &&
                        column !== 'company' &&
                        column !== 'user' &&
                        column !== 'transaction_type' &&
                        column !== 'IncomeType'
                    )
                );
            columns.push(t("Unallocated Amount"), t("Allocation"));
             // Translate the column headers
        const translatedColumns = columns.map(column => t(column));
        setColumnArray(translatedColumns);

            // Extract values from each object in the array
            const valuesArray = bankStatementHistoryUploaded.map(transaction => {
                const row = columns.map(column => {
                    if (column === t("Unallocated Amount")) {
                        return transaction["amount"]; // Assuming "amount" is the column name for the amount value
                    } else if (column === t("Allocation")) {
                        return t("Add Allocation");
                    } else {
                        return transaction[column];
                    }
                });
                return row;
            });
            setValues(valuesArray);
        }
    }, [bankStatementHistoryUploaded]);

    const getAccounts = () => {
        getAccounts_api()
    }
    const getAccounts_api = () => {
        fetch("/apiv2/accounts/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),

            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.text().then(text => {
                    throw new Error(text);
                });
            }
        }).then(data => {
            if (data.success) {
                setAccounts(data.accounts);

            } else {
                toast.error("Failed to get accounts: " + data.error);
            }
        }).catch(error => {
            toast.error("Failed to get accounts: " + error.message);
        });
    }

    // Handle changes in the input fields
    const handleInputChange = (rowIndex, colIndex, value, type) => {
        const updatedValues = [...values];
        const rowAmount = parseFloat(values[rowIndex][1]); // Assuming the amount is in the second column
        let allocatedAmount = 0;

        if (type === "allocation") {
            if (!allocations[rowIndex]) {
                allocations[rowIndex] = [{
                    account: value,
                    amount: "",
                    originalRowObj: bankStatementHistoryUploaded[rowIndex]
                }];
            } else {
                allocations[rowIndex][colIndex] = {
                    account: value,
                    amount: allocations[rowIndex][colIndex].amount,
                    originalRowObj: bankStatementHistoryUploaded[rowIndex]
                };
            }
        } else if (type === "amount") {
            allocations[rowIndex][colIndex].amount = value;
        }

        // Calculate the total allocated amount
        allocations[rowIndex].forEach(allocation => {
            allocatedAmount += parseFloat(allocation.amount) || 0;
        });

        // Calculate the remaining amount
        const remainingAmount = rowAmount - allocatedAmount;

        // Update the unallocated amount in the values array
        updatedValues[rowIndex][updatedValues[rowIndex].length - 2] = remainingAmount.toFixed(2);

        setAllocations({...allocations});
        setValues(updatedValues);
        console.log("values in handle input change", values);
    };
    const handleAddAllocation = (rowIndex) => {
        const unallocatedAmount = parseFloat(values[rowIndex][values[rowIndex].length - 2]);

        if (!allocations[rowIndex]) {
            allocations[rowIndex] = [];
        }

        if (check[rowIndex]) {
            // If the check is true, set it to false
            setCheck(prevState => ({
                ...prevState,
                [rowIndex]: false
            }));
        } else {
            // If the check is false, set it based on the unallocated amount
            setCheck(prevState => ({
                ...prevState,
                [rowIndex]: unallocatedAmount === 0.00
            }));

            if (unallocatedAmount !== 0.00) {
                allocations[rowIndex].push({account: "----", amount: ""});
            }
        }

        setAllocations({...allocations});
        // Create an array of objects representing the allocations
        const allocationDetails = allocations[rowIndex].map(allocation => ({
            account: allocation.account,
            amount: allocation.amount
        }));

        // Map the values array to an object using the columnArray headers
        const rowObject = columnArray.reduce((obj, header, index) => {
            obj[header] = values[rowIndex][index];
            return obj;
        }, {});

        // Add the allocations to the row object
        rowObject.allocations = allocationDetails;

        // Update the modifiedRows state with the original row data and allocations
        setModifiedRows(prevState => {
            const existingRowIndex = prevState.findIndex(row => row[columnArray[0]] === values[rowIndex][0]);
            if (existingRowIndex !== -1) {
                const updatedState = [...prevState];
                updatedState[existingRowIndex] = rowObject;
                return updatedState;
            } else {
                return [...prevState, rowObject];
            }
        });


        console.log("modifiedRows in add allocation", modifiedRows);
        console.log("allocations in add allocation", allocations);

    };
// Create a reference to the hidden file input element
    const hiddenFileInput = useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const updateAllocations = () => {
        updateAllocations_api()
            .then(data => {
                toast.custom(t("Allocations updated Successfully"))

            }).catch(err => {
                toast.error(t("Allocations update failed"))
            }
        )

    }


    const handleFileUpload = (file) => {
        const formData = new FormData();
        const bankaccount_id = selectedBankStatement;
        console.log('bankaccount_id', bankaccount_id);

        formData.append('file', file);

        fetch(`/apiv2/upload_csv/${bankaccount_id.id}/`, {
            method: "POST",
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();

            } else {

                return response.text().then(text => {
                    throw new Error(text);
                });
            }
            return response.json();
        }).then(data => {
            if (data.success) {
                toast.success("File uploaded successfully");
                setBankStatementHistoryUploaded(data.bankstatementTransactions);
                console.log('data', data);
                console.log('bankStatementHistoryUploaded', data.bankstatementTransactions);
            } else {
                toast.error("File upload failed: " + data.error);
            }
        }).catch(error => {
            toast.error("File upload failed: " + error.message);
            console.error("Upload failed", error);
        });
    };

    console.log('data', data)
    console.log('bankStatementHistoryUploaded', bankStatementHistoryUploaded)

    const updateAllocations_api = (user) => {
        return new Promise((resolve, reject) => {
            fetch("/apiv2/transactions/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    allocations,
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        return response.text().then(text => {
                            throw new Error(text)
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.success) {
                        resolve(data);
                    } else {
                        reject(data.message);
                    }
                })
                .catch(err => {
                    reject(err.message || err);
                });
        });
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card-container">
                        <div className="card">
                            <div className="row">
                                <div className="d-flex col-lg-12">

                                    <h1>{t("Import Bank Statement")}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <h4 className="text-center p-1 bg-primary-lt mb-0">{t("Bank Statement")}</h4>
                        <div className="row p-3">
                            <div className="col-lg-12">
                                <div className="responsive-container">
                                    {permissions?.includes("view_transctn") &&
                                        <div style={{margin: "5px"}} className="responsive-combobox">
                                            <ComboBox
                                                data={accounts}
                                                textField="name"
                                                valueField="id"
                                                placeholder={t("...Select Bank Account")}
                                                onChange={(e) => setSelectedBankStatement(e.target.value)}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            />
                                        </div>}
                                    <div className="responsive-container">

                                        {permissions?.includes("view_transctn") &&
                                            selectedBankStatement &&

                                                <BankStatementUpload
                                                handleFileUpload={handleFileUpload}
                                                setFiles={setFiles}
                                                setBankStatementDetails={setBankStatementDetails}
                                                onError={(error) => toast.error("Upload error: " + error.message)}
                                                files={files}
                                                theme={theme}

                                            />
                                        }
                                        {permissions?.includes("allocate_transctn") &&
                                            modifiedRows.length > 0 &&
                                            <Button className="btn btn-success responsive-button"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '50%',
                                                        height: '100%',
                                                        borderRadius: '15px',
                                                    }}
                                                    onClick={updateAllocations}
                                            >
                                                {t("Process")}
                                            </Button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {permissions?.includes("view_transctn") &&
                    <div className="col-lg-12">
                        <div className="card align-items-lg-start">
                            <h4 className="text-center p-1 bg-primary-lt mb-0"
                                style={{margin: "15px"}}>
                                {t("Transaction History")}"
                            </h4>

                            <div className="table-responsive">
                                <table
                                    className='table table-hover table-striped mb-0'
                                >
                                    <thead>
                                    <tr>
                                        {columnArray.map((column, i) => {
                                            return <th
                                                key={i}
                                                className={column === t("Unallocated Amount") || column === t("Allocation") ? 'new-column' : ''}
                                            >
                                                {column}
                                            </th>
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {values.map((v, rowIndex) => {
                                        return <tr
                                            key={rowIndex}>
                                            {v.map((value, colIndex) => {
                                                return (<>
                                                    <td
                                                        key={colIndex}
                                                        data-label={columnArray[colIndex]}
                                                    >
                                                        {colIndex < v.length - 1 ? value : (
                                                            <>
                                                                {allocations[rowIndex] && allocations[rowIndex].map((allocation, index) => (
                                                                    (parseFloat(values[rowIndex][values[rowIndex].length - 2]) !== 0.00 || allocation.amount !== "") && (
                                                                        <div key={index}
                                                                        >
                                                                            <select
                                                                                value={allocation.account}
                                                                                disabled={check[rowIndex]}
                                                                                onChange={(e) => handleInputChange(rowIndex, index, e.target.value, "allocation")}
                                                                                style={{
                                                                                    borderCollapse: "collapse",
                                                                                    border: "1px solid grey",
                                                                                    borderRadius: "5px",
                                                                                    margin: "5px auto",
                                                                                    padding: "10px",
                                                                                    color: check[rowIndex] ? "rgba(16,218,236,0.6)" : (parseFloat(values[rowIndex][values[rowIndex].length - 2]) !== 0.00 ? "rgba(236,16,16,0.6)" : "rgba(16,236,67,0.6)")
                                                                                }}
                                                                            >
                                                                                <option value="----">----</option>
                                                                                {accounts.map((account, i) => (
                                                                                    <option key={i}
                                                                                            disabled={check[rowIndex]}
                                                                                            value={account.name}>{account.name}</option>
                                                                                ))}
                                                                            </select>
                                                                            <input
                                                                                type="number"
                                                                                value={allocation.amount}
                                                                                disabled={check[rowIndex]}
                                                                                onChange={(e) => handleInputChange(rowIndex, index, e.target.value, "amount")}
                                                                                style={{
                                                                                    borderCollapse: "collapse",
                                                                                    border: "1px solid black",
                                                                                    borderRadius: "5px",
                                                                                    margin: "5px",
                                                                                    padding: "10px",
                                                                                    color: check[rowIndex] ? "rgba(16,218,236,0.6)" : (parseFloat(values[rowIndex][values[rowIndex].length - 2]) !== 0.00 ? "rgba(236,16,16,0.6)" : "rgba(16,236,67,0.6)")

                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                ))}

                                                                {permissions?.includes("allocate_transctn") &&
                                                                check[rowIndex] ? (
                                                                    <>
                                                                        <Button
                                                                            className="btn btn-ghost-success"
                                                                            style={{color: "#10DAEC99"}}
                                                                            onClick={() => handleAddAllocation(rowIndex)}
                                                                        >
                                                                            {t("Change Allocation")}
                                                                        </Button>
                                                                        <FaCheck
                                                                            style={{color: "#10DAEC99"}}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    permissions?.includes("allocate_transctn") &&
                                                                    <Button
                                                                        className={parseFloat(values[rowIndex][values[rowIndex].length - 2]) !== 0.00 ? "btn btn-ghost-danger" : "btn btn-ghost-success"}
                                                                        onClick={() => handleAddAllocation(rowIndex)}
                                                                    >
                                                                        {t("Add Allocation")}
                                                                    </Button>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>
                                                </>)
                                            })}
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
            </div>
            <div className="col-lg-6">
            </div>
        </div>
    )
        ;

}