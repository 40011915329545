import React, {useEffect, useState, useRef} from 'react';
import {useAuth} from "../hooks/useAuth";
import { useLanguageStore } from "../hooks/useLanguage";

import {MemberList} from "../components/MemberList";
import {AddFamilyModal} from "../modals/AddFamilyModal";
import {useTheme} from "../hooks/useTheme";

import { usePermissions} from "../hooks/PermissionContext";
import toast from "react-hot-toast";
import {EditFamilyModal} from "../modals/EditFamilyModal";
import {MemberModal} from "../modals/MemberModal";
import {getCookie} from "../func/Func";
import '../Custom.css';

export const Members = (props) => {
    const { t } = useLanguageStore();
    const permissions = usePermissions();
    const {user} = useAuth();
    const {theme} = useTheme();
    const [members, setMembers] = useState([]);
    const [totalMembersList, setTotalMembersList] = useState([]);
    const [titles, setTitles] = useState([]);
    const [noteTypes, setNoteTypes] = useState([]);
    const [professions, setProfessions] = useState([]);
    const [families, setFamilies] = useState([]);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showAddFamilyModal, setShowAddFamilyModal] = useState(false);
    const [showMemberModal, setShowMemberModal] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [membersLoading, setMembersLoading] = useState(false);
    const debounceTimeout = useRef(null);
    const [selectedFamily, setSelectedFamily] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [showEditFamilyModal, setShowEditFamilyModal] = useState(false);
    const [memberVisits, setMemberVisits] = useState([]);
    // FILTERS
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortBy, setSortBy] = useState('UniqueLidNo');
    const [sortOrder, setSortOrder] = useState('asc');
    const [search, setSearch] = useState('');

    const getMembers = (search_override = null) => {
        let search_str = "";
        if (search_override !== null) {
            search_str = search_override;
        } else {
            search_str = search;
        }
        setMembersLoading(true);
        fetch(`/apiv2/members/?page=${page}&sort_order=${sortOrder}&sort_by=${sortBy}&search=${search_str}`, {
            method: 'GET',
        }).then(res => res.json())
            .then(data => {
                if (data?.total_pages) {
                    setTotalPages(data.total_pages);
                    setMembers(data?.members);
                    setPage(data?.page);
                }
                setMembersLoading(false);
            })
            .catch(err => {
                console.log(err);
                setMembersLoading(false);
            })
    }

    const getMember = (member_id) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/member/${member_id}/`, {
                method: 'GET',
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    const getFamily = (family_id) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/family/${family_id}/`, {
                method: 'GET',
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        });
    }

    useEffect(() => {
        getMembers();
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);


    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        getMembers();
    }, [sortBy, sortOrder, page])

    const selectedFamilyChanged = (family_id) => {
        getFamily(family_id)
            .then(data => {
                if (data?.success) {
                    setSelectedFamily(data?.data);
                } else {
                    toast.error(t("Failed to get family data"))
                }
            }).catch(err => {
            console.log(err);
        });
    }

    const selectedMemberChanged = (member) => {
        getMember(member?.id)
            .then(data => {
                if (data?.success) {
                    setSelectedMember(data?.data);
                    setTitles(data?.titles);
                    setProfessions(data?.professions);
                    setNoteTypes(data?.noteTypes);


                } else {
                    toast.error(t("Failed to get member data"))
                }
            }).catch(err => {
            console.log(err);
        });
        getMemberVisits(member).then(data => {
            if (data?.status === 0) {
                setMemberVisits(data?.visits);
            } else {
                toast.error(t("Failed to get member visits"))
            }
        })
    }

    const getMemberVisits = (member) => {
        return new Promise((resolve, reject) => {
            // fetch(`/apiv2/member_visits/${member.id}/`, {
            fetch(`/apiv2/visits/`, {
                method: 'GET',
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }


    const handleShowSearchModal = () => {
        setShowSearchModal(true);
    }

    const handleCloseSearchModal = () => {
        setShowSearchModal(false);
    }

    const handleShowAddFamilyModal = () => {
        setShowAddFamilyModal(true);
    }

    const handleCloseAddFamilyModal = () => {
        setShowAddFamilyModal(false);
    }

    const handleShowEditFamilyModal = () => {
        setShowEditFamilyModal(true);
    }

    const handleCloseEditFamilyModal = () => {
        setShowEditFamilyModal(false);
    }

    const handleShowMemberModal = () => {
        setShowMemberModal(true);
    }

    const handleCloseMemberModal = () => {
        setShowMemberModal(false);
    }


    const rowRightClick = (e, member) => {
        e.preventDefault();
        setContextMenuX(e.clientX);
        setContextMenuY(e.clientY);
        setShowContextMenu(true);
        selectedMemberChanged(member);
    }

    const rowClick = (e, member) => {
        setSelectedFamily(null);
        // selectedMemberChanged(member);
        selectedFamilyChanged(member?.family_id);
        setShowEditFamilyModal(true);
    }


    const handleCloseContextMenu = () => {
        setShowContextMenu(false);
    }

    const saveEditedMember = (member) => {
        const formData = new FormData();
        for (const key in member) {
            formData.append(key, member[key]);
        }
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/member/`, {
                method: 'PUT',
                headers: {
                    // 'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: formData
            }).then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    // const searchChanged = (e) => {
    //   setSearch(e.target.value);
    // }


    const searchChanged = (e) => {
        const value = e.target.value;
        setSearch(value);

        // Clear the previous timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to call the search function after 1000ms
        debounceTimeout.current = setTimeout(() => {
            getMembers(value);
        }, 1000);
    }

    return (
        <>
            {permissions?.permissions?.includes('modify_members') &&
                <AddFamilyModal
                    show={showAddFamilyModal}
                    onShow={handleShowAddFamilyModal}
                    onHide={handleCloseAddFamilyModal}
                    user={user}
                />}
            {permissions?.permissions?.includes('view_members') &&
                <EditFamilyModal
                    show={showEditFamilyModal}
                    onShow={handleShowEditFamilyModal}
                    onHide={handleCloseEditFamilyModal}
                    selectedFamily={selectedFamily}
                    selectedMember={selectedMember}
                    theme={theme}
                    selectedMemberChanged={selectedMemberChanged}
                    handleShowMemberModal={handleShowMemberModal}
                    setSelectedFamily={setSelectedFamily}
                    getFamily={getFamily}
                    getMembers={getMembers}

                />}
            {permissions?.permissions?.includes('view_members') &&
                <MemberModal
                    show={showMemberModal}
                    onShow={handleShowMemberModal}
                    onHide={handleCloseMemberModal}
                    selectedMember={selectedMember}
                    setSelectedMember={setSelectedMember}
                    selectedMemberChanged={selectedMemberChanged}
                    theme={theme}
                    memberVisits={memberVisits}
                    saveEditedMember={saveEditedMember}
                    selectedFamilyChanged={selectedFamilyChanged}
                    titles={titles}
                    setTitles={setTitles}
                    professions={professions}
                    setProfessions={setProfessions}
                    noteTypes={noteTypes}
                    setNoteTypes={setNoteTypes}
                    user={user}
                    getFamily={getFamily}

                />}
            {permissions?.permissions?.includes('view_members') &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card-container">
                                <div className="card">
                                    <MemberList
                                        members={members}
                                        showSearchModal={showSearchModal}
                                        handleShowSearchModal={handleShowSearchModal}
                                        handleCloseSearchModal={handleCloseSearchModal}
                                        rowRightClick={rowRightClick}
                                        rowClick={rowClick}
                                        handleCloseContextMenu={handleCloseContextMenu}
                                        handleShowAddFamilyModal={handleShowAddFamilyModal}
                                        page={page}
                                        setPage={setPage}
                                        totalPages={totalPages}
                                        sortBy={sortBy}
                                        setSortBy={setSortBy}
                                        sortOrder={sortOrder}
                                        setSortOrder={setSortOrder}
                                        setSearch={setSearch}
                                        searchChanged={searchChanged}
                                        search={search}
                                        selectedMemberChanged={selectedMemberChanged}
                                        theme={theme}
                                        totalMembersList={totalMembersList}
                                    />
                                </div>
                                {membersLoading && (
                                    <div className={`card-overlay-${theme}`}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">{t("Loading...")}</span>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}