import * as React from 'react';
import { useLanguageStore } from "../hooks/useLanguage";
import {useAuth} from "../hooks/useAuth";
import {Form, Field, FormElement} from "@progress/kendo-react-form";
import {Input} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import toast from "react-hot-toast";
import {FaTimes} from "react-icons/fa";
import "./Styles/changepasswordmodal.css";

export const ChangePasswordModal = (props) => {
    const {
        handleCloseChangePasswordModal,
        theme
    } = props;

    const { t } = useLanguageStore();
    const {changePassword} = useAuth();

    const handleSubmit = async (dataItem) => {
        const response = await changePassword(dataItem);
        if (response===true) {
            toast.success(t("Password Changed Successfully"));
            handleCloseChangePasswordModal();
        } else {
            toast.error(t("Password Change Failed"));
        }
    }

    const requiredValidator = (value) => value ? "" : t("This field is required.");
    const passwordMatchValidator = (value, formRenderProps) => value === formRenderProps.new_password ? "" : t("Passwords do not match.");
    const minLengthValidator = (value) => value && value.length >= 8 ? "" : t("Password must be at least 8 characters long.");


    const onModalHide = () => {
        handleCloseChangePasswordModal();
    }


    return (
        <div className="modal-overlay">
            <div className={theme == "dark" ? ("modal-content-dark") : ("modal-content-light")}>
                <div className="row">
                    <div className="d-flex col-lg-12">
                        <h3>
                            {t("Change Password")}
                        </h3>
                        <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onModalHide}/>
                    </div>
                </div>
                <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <Field
                                name={"current_password"}
                                label={t("Current Password")}
                                component={Input}
                                type={"password"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"new_password"}
                                label={t("New Password (min 8 characters)")}
                                component={Input}
                                type={"password"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"confirm_password"}
                                label={t("Confirm Password")}
                                component={Input}
                                type={"password"}
                                validator={[requiredValidator,minLengthValidator]}
                            />
                            <Button
                                type={"submit"}
                                disabled={!formRenderProps.allowSubmit}
                            >
                                {t("Submit")}
                            </Button>
                        </FormElement>
                    )}
                />
            </div>
        </div>
    )
};