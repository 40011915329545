import React, {useState} from "react";
import "../pages/Styles/BankStatement.css";
import {Upload} from "@progress/kendo-react-upload";
import {loadMessages, LocalizationProvider} from "@progress/kendo-react-intl";
import {useTranslation} from "react-i18next";
import {usePermissions} from "../hooks/PermissionContext";

const BankStatementUpload = ({handleFileUpload, setFiles, setBankStatementDetails, onError, files = []}) => {
    const permissions = usePermissions();
    const {t} = useTranslation();
    const [renderCustomVariables, setRenderCustomVariables] = useState(true);
    const onAdd = (event) => {
        const file = event.affectedFiles[0].getRawFile();
        handleFileUpload(file);
        // onChange({ value: file });
        setFiles(event.newState);
        setBankStatementDetails(prevDetails => ({...prevDetails, data: file}));

    };

    const onRemove = () => {
        setFiles([]);
        setBankStatementDetails(prevDetails => ({...prevDetails, data: null}));

    };
    loadMessages(
        {
            upload: {
                dropFilesHere: t("Drop CSV bankstatement here to upload"),
                select: t("Upload Bankstatement (CSV)"),
            },
        },
        "myCustomMessages"
    );


    return (
        <div className="k-form-field-wrap responsive-upload">
            <LocalizationProvider language="myCustomMessages">
                <Upload
                    batch={false}
                    multiple={false}
                    defaultFiles={[]}
                    withCredentials={false}
                    files={files ? files[0] : []}
                    onAdd={onAdd}
                    onError={onError}
                    onRemove={onRemove}

                    saveUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/save"}
                    removeUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/remove"}
                />
            </LocalizationProvider>
        </div>
    );
};

export default BankStatementUpload;