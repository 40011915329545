import React from 'react';
import {FaExclamationTriangle} from "react-icons/fa";
import { useLanguageStore } from "../hooks/useLanguage";

const colorOptions = [
  // "blue-lt",
  // "orange-lt",
  // "azure-lt",
  // "info-lt",
  // "purple-lt",
  // "red-lt",
  // "green-lt",
  "lime-lt",
  // "indigo-lt",
  // "teal-lt",
  // "pink-lt",
  // "yellow-lt",
  "cyan-lt",
]

export const IncomeTypeList = (props) => {
  const { t } = useLanguageStore();
  let {
    incomeTypes,
    incomeTypeSelected,
    incomeTypeToEdit,
    setIncomeTypeToEdit,
    showAddFundToIncomeTypeModal
  } = props;

  const InvalidFundPercentage = (props) => {
    let {incomeType} = props;
    return (
      <>
        {incomeType?.total_percentage !== 100 && (
          <h3 className={"badge badge-pill text-center bg-danger-lt m-0 ms-2"}>
            {/*<FaExclamationTriangle className={"text-danger m-0 me-1"}/>*/}
            {/*Invalid fund percentages*/}
            {t("Invalid")}
          </h3>
        )}
      </>

    )
  }

  return (
    <>

      <div className="col-12">
        <div className="card card-table">
          <div className="table-responsive rounded">
            {/*<table className="table table-striped table-hover rounded">*/}
            <table className="table table-hover rounded">
              <thead>
              <tr>
                <th>{t("Income Type")}</th>
                <th>{t("Percentage")}</th>
                <th>{t("Fund")}</th>
              </tr>
              </thead>
              <tbody>
              {incomeTypes?.map((incomeType, incomeTypeIndex) => {
                const colorIndex = incomeTypeIndex % colorOptions.length;
                let itemColorClass = colorOptions[colorIndex];
                let firstFund = incomeType?.independent_funds?.[0];
                let remainingFunds = incomeType?.independent_funds?.slice(1);
                // itemColorClass = "";
                if(incomeType?.total_percentage !== 100) {
                  itemColorClass = "danger-lt";
                }
                return (
                  <>
                    {/*<tr key={incomeTypeIndex} onClick={() => incomeTypeSelected(incomeType)}>*/}
                    <tr key={incomeTypeIndex} onClick={() => incomeTypeSelected(incomeType)} className={`bg-${itemColorClass}`}>
                      <td className={`bg-${itemColorClass}`}>
                        {incomeType?.name}
                        <InvalidFundPercentage incomeType={incomeType}/>
                      </td>
                      <td className={`bg-${itemColorClass}`}>
                        <span className={"badge bg-primary-lt"}>{firstFund?.percentage}%</span>
                      </td>
                      <td className={`bg-${itemColorClass}`}>{firstFund?.name}</td>

                    </tr>
                    {remainingFunds?.map((fund, fundIndex) => {
                      return (
                        // <tr key={fundIndex}>
                        <tr key={fundIndex} onClick={() => incomeTypeSelected(incomeType)}>
                        <td></td>
                          <td className={`bg-${itemColorClass}`}>
                            <span className={"badge bg-primary-lt"}>{fund?.percentage}%</span>
                          </td>
                          <td className={`bg-${itemColorClass}`}>{fund?.name}</td>
                          {/*<td>{fund?.name}</td>*/}
                        </tr>
                      )
                    })}
                  </>


                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}