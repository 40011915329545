import React, { useEffect, useState } from 'react';
import { useLanguageStore } from "../hooks/useLanguage";
import Modal from 'react-bootstrap/Modal';
import { FaSave, FaTimes, FaUserPlus } from "react-icons/fa";
import { getCookie } from "../func/Func";
import toast from "react-hot-toast";
import { ConfirmModal } from "./ConfirmModal";
import { FamilyMemberModal } from "./FamilyMemberModal";
import { FaEnvelope } from "react-icons/fa6";

export const MessagesSent = (props) => {
    const { t } = useLanguageStore();
    const {
        show,
        onShow,
        onHide,
        selectedbirthdayid,
    } = props;

    const [members, setMembers] = useState([]);
    const [messagesSent, setMessagesSent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        if (selectedbirthdayid) {
            getMessagesSent();
        }
    }, [selectedbirthdayid]);

    const getMessagesSent_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_messages_sent/${selectedbirthdayid}`, {})
                .then(res => res.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data.data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
    const getMessagesSent = () => {
        getMessagesSent_api(selectedbirthdayid)
            .then((data) => {
                setMessagesSent(data)
                setIsLoaded(true)
                // console.log("id", selectedbirthdayid)

            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const onModalHide = () => {
        onHide();
    }


    return (
        <>

            <Modal
                size={'lg'}
                show={show}
                onHide={onHide}
                centered={true}
                className={"modal-blur scrollable"}
                backdrop={"static"}

            >
                <Modal.Body>
                    <div className="row">
                        <div className="d-flex justify-content-between flex-row">
                            <span className='h3'>
                                {t("Previous Messages Sent")}
                            </span>
                            <span>
                                <FaTimes size={20} className={"cursor-pointer"} onClick={onHide} />
                            </span>
                        </div>
                    </div>
                    <div className="table-responsive">
                        {messagesSent?.length > 0 ?
                            <table className='table table-striped'>
                                <tbody>

                                    <tr>
                                        <th>{t('Message ID')}</th>
                                        <th>{t('Subject')}</th>
                                        <th>{t('Sent By')}</th>
                                        <th>{t('Date Sent')}</th>
                                        <th>{t('Message')}</th>
                                    </tr>

                                    {messagesSent.map((msg, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{msg.id}</td>
                                                <td>{msg.subject}</td>
                                                <td>{msg.from_name}</td>
                                                <td>
                                                    {new Date(msg.dt_created).toLocaleString("en-GB", {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: false,
                                                    })}
                                                </td>
                                                <td style={{ textAlign: 'left' }}>{msg.message_data}</td>


                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> : <h5>{t('No Messages sent yet')}</h5>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}