import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaSave, FaTimes, FaSearch, FaUpload } from "react-icons/fa";
import { getCookie } from "../func/Func";
import toast from "react-hot-toast";
import { useLanguageStore } from "../hooks/useLanguage";

export const SubgroupModal = (props) => {
    const { t } = useLanguageStore();
    const {
        onShow,
        onHide,
        theme,
        user,
        show,
        group,
        groupMembers,
        searchChanged,
        search,
        members,
        membersSearching,
    } = props;

    const [groupToEdit, setGroupToEdit] = useState({});

    useEffect(() => {
        if (show && group) {
            setGroupToEdit(group);
        }
    }, [show, group]);

    const groupNameChanged = (e) => {
        setGroupToEdit({ ...groupToEdit, name: e.target.value });
    }

    const saveGroup = (e) => {
        e.preventDefault();
        if (!groupToEdit?.name) {
            toast.error(t('Please enter a group name'));
            return;
        }
    }

    const removeGroupMember = (member) => {

    }

    return (
        <>
            <Modal
                size={'md'}
                show={show}
                onHide={onHide}
                // centered={true}
                // className={`modal-blur`}
                className={`modal-custom-backdrop-${theme}`}
                backdrop={"static"}
            // dialogClassName={`box-shadow-${theme}`}
            >
                <Modal.Body className={`box-shadow-${theme} rounded-3`}>
                    <div className="row">
                        <div className="d-flex justify-content-between flex-row">
                            <span className='h3'>
                                {t("Subgroup")}
                            </span>
                            <span>
                                <FaTimes size={20} className={"cursor-pointer"} onClick={onHide} />
                            </span>
                        </div>
                    </div>
                    <div className="pt-0">
                        <form action="#" className={"row"}>
                            <div className="col-lg-6 col-sm-12 col-md-12 mb-2">
                                <div className="form-group">
                                    <label htmlFor="name">{t("Group Name")}</label>
                                    <input onInput={groupNameChanged} value={groupToEdit?.name ? groupToEdit?.name : ''} required={true}
                                        type="text" className="form-control" id="name" placeholder={t("Group Name")} />
                                </div>
                            </div>

                            <div className="col-12 mb-2">
                                <span className="h3">{t("Group members")}</span>
                                <div className="row mt-2">
                                    {groupMembers && groupMembers?.map((member, memberIndex) => {
                                        return (
                                            <div key={memberIndex} className="col-12 d-flex justify-content-left flex-row py-1">
                                                <span className='me-2'>
                                                    {member?.Names} {member?.Surname}
                                                </span>
                                                <span className='text-light'>
                                                    <FaTimes size={20} className={"cursor-pointer bg-danger rounded"} />
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="input-icon">
                                        <input type="text" value={search} onInput={searchChanged} className="form-control"
                                            placeholder={t("Search…")} />
                                        <span className="input-icon-addon">
                                            <FaSearch />
                                        </span>
                                    </div>
                                </div>

                                {membersSearching && (
                                    <div className="col-12 mt-2">
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* {!membersSearching && members?.length < 1 && search?.length >= 3 && (
                                    <div className="col-12 mt-2">
                                        <div className="d-flex justify-content-center bg-danger-lt rounded py-2">
                                            <span>{t("No members found")}</span>
                                        </div>
                                    </div>
                                )} */}
                                {!membersSearching && members?.length > 0 && (
                                    <div className="col-12 mt-2" style={{height: '36dvh', overflowY: 'auto'}}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{t("Member")}</th>
                                                    <th>{t("Add member")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {members && members?.map((member, memberIndex) => {
                                                    return (
                                                        <tr key={memberIndex} className="">
                                                            <td>
                                                                {member?.Names} {member?.Surname}
                                                            </td>
                                                            <td className='p-0 m-0 align-content-center'>
                                                                <FaUpload size={24} className={"cursor-pointer bg-success-lt rounded"} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>


                            <div className="col-lg-12">
                                <button onClick={saveGroup} type={'button'}
                                    className="btn btn-primary  w-100">
                                    <FaSave className={"me-2"} />
                                    {t("Save")}
                                </button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    );
}

