import React, { useState, useEffect } from 'react';
import { useAuth } from "../hooks/useAuth";
import { useTheme } from "../hooks/useTheme";
import { useLanguageStore } from "../hooks/useLanguage";
import PermissionProfiles from "../components/PermissionProfiles";
import toast from "react-hot-toast";
import { usePermissions } from "../hooks/PermissionContext";

const UserManagement = () => {
    const { t } = useLanguageStore();
    const permissions = usePermissions();
    const { user } = useAuth();
    const { theme } = useTheme();

    const [isLoaded, setIsLoaded] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);
    const [allMembers, setAllMembers] = useState([]);


    useEffect(() => {
        viewAllMembers();
        setTimeout(() => {
            setIsLoaded(true); // Set isLoaded to true after loading

        }, 1000); // 2 seconds delay for simulation
    }, []); // Empty dependency array ensures this runs only once


    const getPermissionProfiles = (getPermissionProfiles_api) => {
        getPermissionProfiles_api()
            .then((data) => {
                const permissionsArray = data.permissions ? Object.values(data.permissions) : [];
                setUserPermissions(permissionsArray)
                toast.success(t("Permission Profiles loaded successfully"))
            })
            .catch((err) => {
                toast.error(t("An error occurred while loading Permission Profiles") + err.message)
            })
    }

    const viewAllMembers = () => {
        viewAllMembers_api()
            .then((data) => {
                const membersArray = data.data ? Object.values(data.data) : [];
                setAllMembers(membersArray)
                toast.success(t("All Members loaded successfully"))
            })
            .catch((err) => {
                toast.error(t("An error occurred while loading All Members"))
            })
    }



    const viewAllMembers_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/api_view_all_users`, {})
                .then(res => {
                    if (!res.ok) {
                        return res.text()
                            .then(text => {
                                throw new Error(t("An error occurred while loading All Members") + text);
                            });
                    }
                    return res.json();
                })

                // .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data)
                    } else {
                        reject(new Error(t("An error occurred while loading All Members")));
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }


    return (

        <>
            {isLoaded ? (
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-m-12 col-sm-12">
                                <div className="card-container">
                                    <div className="card">
                                        <h1 style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            paddingTop: "1%",
                                            color: "#0d6dfb"
                                        }}>
                                            {t("User Management")}
                                        </h1>
                                        {permissions?.permissions?.includes("view_permissions") &&
                                            <PermissionProfiles
                                                user={user}
                                                getPermissionProfiles={getPermissionProfiles}
                                                permissions={userPermissions}
                                                theme={theme}
                                                allMembers={allMembers}
                                            />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-container">
                                    <div className="card">
                                        <h1>{t("Loading...")}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>

    );

};


export default UserManagement;