import React, {useEffect, useState} from 'react';
import { useLanguageStore } from "../hooks/useLanguage";
import toast from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import {FaTimes} from "react-icons/fa";
import {getCookie} from "../func/Func";
import {MultiSelect} from "@progress/kendo-react-dropdowns";
import {Hint} from "@progress/kendo-react-labels";
import '@progress/kendo-theme-default/dist/all.css'; // Ensure Kendo UI CSS is included

export const AddVisitModal = (props) => {
    const { t } = useLanguageStore();
    const {
        showVisit,
        onHideVisit,
        theme,
        setAddVisitModal,
        addVisitModal,
        selectedMember,
        user,
    } = props;

    const [visit, setVisit] = useState({
        member_id: "",
        visit_date: '',
        visit_time: '',
        reason: 2, //reason.name
        visit_notes: '',
        dt_created: '',
        visiting_user: user, //FullNames of user
        visited_user: null, //user object
    });
    const [staff, setStaff] = useState([]);
    const [reasons, setReasons] = useState([]);

    useEffect(() => {
        getStaff();
        getReasons();
        setVisit({
            member_id: '',
            visit_date: '',
            visit_time: '',
            reason: 2,
            visit_notes: '',
            dt_created: '',
            visiting_user: user,
            visited_user: selectedMember,
        });
    }, [props.show, selectedMember]);

    const handleVisit = (e) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/add_visit/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: JSON.stringify(visit)
            }).then(res => res.json())
                .then(data => {
                    if (data.status === 0) {
                        resolve(data);
                        onHideVisit();
                    } else {
                        reject(data);
                    }
                })
                .catch(err => {
                    reject(err);
                })
        });

    };

    const getReasons = () => {
        getVisitReasons_api().then(data => {
            setReasons(data.data);
        }).catch(err => {
            console.log(err);
        });
    };

    const getStaff = () => {
        getStaff_api().then(data => {
            setStaff(data.data);
        }).catch(err => {
            console.log(err);
        });
    };

    const getStaff_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_staff/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            }).then(res => res.json())
                .then(data => {
                    if (data.success === true) {
                        resolve(data);
                    } else {
                        reject(data);
                    }
                })
                .catch(err => {
                    reject(err);
                })
        });
    };

    const getVisitReasons_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_visit_reasons/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            }).then(res => res.json())
                .then(data => {
                    if (data.success === true) {
                        resolve(data);
                    } else {
                        reject(data);
                    }
                })
                .catch(err => {
                    reject(err);
                })
        });
    };

    const visitValueChanged = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'staff' && !isNaN(value)) {
            value = Number(value);
        }

        setVisit({...visit, visiting_user: value});
    };

    const visitReasonChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'reason' && !isNaN(value)) {
            value = Number(value);
        }

        setVisit({...visit, reason: value});
    };

    return (
        <>
            {showVisit &&
                <Modal
                    size={'lg'}
                    show={showVisit}
                    onHide={onHideVisit}
                    centered={true}
                    className={`modal-custom-backdrop-${theme}`}
                    backdrop={"static"}
                >
                    <Modal.Body className={`box-shadow-${theme} rounded-3`}>

                        <div className="row">
                            <div className="d-flex col-lg-12">
                                <h3>{t("Add Visit")}</h3>
                                <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHideVisit}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <h4 className={"text-center p-1 bg-primary-lt mb-0"}>{t("Visit Information")}</h4>
                                    <div className="row p-3">
                                        <div className="col-lg-12">
                                            <section className="modal-card-body">
                                                <form onSubmit={handleVisit}>

                                                    <div style={{margin:5}}>
                                                        <label>{t("Visited by")}</label>
                                                        <select onChange={visitValueChanged} name={"staff"}
                                                                className="form-control"
                                                                value={visit.visiting_user || ''}>
                                                            {staff.map((st, index) => {
                                                                return (
                                                                    <option key={index}
                                                                            value={st.id}>{st.username}</option>
                                                                )
                                                            })}
                                                        </select></div>
                                                    
                                                        <div style={{margin:5}}>
                                                            <label >{t('Visit Reason')}</label>
                                                            <div >
                                                                <select onChange={visitReasonChange} name={"reason"}
                                                                        className="form-control"
                                                                        value={visit.reason || ''}>
                                                                    {reasons.map((reason, index) => {
                                                                        return (
                                                                            <option key={index}
                                                                                    value={reason.id}>{reason.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div style={{margin:5}}>
                                                            <label >{t('Visit Date')}</label>
                                                            <div >
                                                                <input className="form-control" type="date"
                                                                       placeholder={t('Visit Date')}
                                                                       value={visit.visit_date}
                                                                       onChange={(e) => setVisit({
                                                                           ...visit,
                                                                           visit_date: e.target.value
                                                                       })}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12" style={{margin:5}}>
                                                            <label >{t('Visit Notes')}</label>
                                                            <div >
                                                                <textarea className="form-control"
                                                                       placeholder={t('Notes')}
                                                                       value={visit.visit_notes}
                                                                          rows={3}

                                                                       onChange={(e) => setVisit({
                                                                           ...visit,
                                                                           visit_notes: e.target.value
                                                                       })}/>
                                                            </div>
                                                        </div>

                                                </form>
                                            </section>
                                            <footer className="modal-card-foot">
                                                <button className="btn btn-primary"
                                                        onClick={handleVisit}>{t('Save')}</button>
                                                <button className="btn btn-outline-secondary"
                                                        onClick={onHideVisit}>{t('Cancel')}</button>
                                            </footer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            }
        </>
    )
}