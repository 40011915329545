import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const PermissionContext = createContext();

export const usePermissions = () => useContext(PermissionContext);

export const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    // Fetch user permissions from the backend
    axios.get('/apiv2/userpermissions/')
      .then(response => setPermissions(response.data))
      .catch(error => console.error('Error fetching permissions:', error));
  }, []);

  return (

    <PermissionContext.Provider value={permissions}>
      {children}
    </PermissionContext.Provider>
  );
};