import React, { useEffect, useState } from 'react';
import { useLanguageStore } from "../hooks/useLanguage";
import Modal from 'react-bootstrap/Modal';
import { FaSave, FaTimes, FaUserPlus, FaPlane } from "react-icons/fa";
import { FaUsers, FaAt, FaEnvelope, FaTelegram, FaMessage, FaRegMessage, FaWhatsapp } from "react-icons/fa6";
import { MdSend } from "react-icons/md";
import { getCookie } from "../func/Func";
import toast from "react-hot-toast";
import { ConfirmModal } from "./ConfirmModal";
import data from "bootstrap/js/src/dom/data";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

export const BirthdayMessageModal = (props) => {
    const { t } = useLanguageStore();
    const {
        show,
        onShow,
        onHide,
        selectedbirthday,
        user,
    } = props;

    const [members, setMembers] = useState([]);
    const [message, setMessage] = useState("");
    const [confirmModalText, setConfirmModalText] = useState("Are you sure you want to add this family?");
    const [confirmModalTitle, setConfirmModalTitle] = useState("Add Family");
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [email, setEmail] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [telegram, setTelegram] = useState(false);
    const [sms, setSms] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [popoverMessage, setPopoverMessage] = useState("No message was typed");
    const [popoverHeader, setPopoverHeader] = useState("Error");


    const [selectedMember, setSelectedMember] = useState(null);

    const onModalHide = () => {
        onHide();
        setEmail(false);
        setWhatsapp(false);
        setSms(false);
        setTelegram(false);
        setMessage("")
    }

    const onHideConfirmModal = () => {
        setShowConfirmModal(false);
    }

    const onConfirm = () => {
        setMembers([]);
        onHide();
        onHideConfirmModal();
    }

    const onCancel = () => {
        onHideConfirmModal();
    }
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const SendMessage_api = () => {
        return new Promise((resolve, reject) => {
            let url = `/apiv1/generate_message/`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    recipient: selectedbirthday.id,
                    sms: sms,
                    whatsapp: whatsapp,
                    email: email,
                    telegram: telegram,
                    message: message,
                }),
            }).then((data) => data.json())
                .then((data) => {
                    if (data.errorCode === 0) {
                        resolve(data);
                    } else {
                        reject(data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    const onMessageSent = () => {
        if (message && (email || whatsapp || sms || telegram)) {
            SendMessage_api().then((data) => {
                toast.success(t("Message was sent successfully"))
                setShowPopover(true)
                setPopoverMessage(t("Message was sent successfully"))
                setPopoverHeader(t("Success"))
                setTimeout(() => {
                    setShowPopover(false)
                }, 2000)


            }).catch((err) => {
                console.log('Error:', err)
            }
            )
            setEmail(false)
            setWhatsapp(false)
            setTelegram(false)
            setSms(false)
            setMessage("")
            setTimeout(() => {
                onHide()
            }, 3000)
            // alert(`Birthday Message Sent: ${message}: ${selectedbirthday.BirthDate}`);
        } else {
            setShowPopover(true)
            setPopoverMessage(t("Select Message Type and Type a Message"))
            setPopoverHeader("Error")
            setTimeout(() => {
                setShowPopover(false)
            }, 2000) // 2000 milliseconds = 2 seconds
        }
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h1"  >{popoverHeader}</Popover.Header>
            <Popover.Body>
                {popoverMessage}
            </Popover.Body>
        </Popover>
    );


    const onEmailClick = (e, email) => {
        setEmail(e.target.checked)
    }

    const onSmsClick = (e, sms) => {
        setSms(e.target.checked)
    }

    const onWhatsappClick = (e, whatsapp) => {
        setWhatsapp(e.target.checked)
    }

    const onTelegramClick = (e, telegram) => {
        setTelegram(e.target.checked)
    }


    return (
        <>
            <ConfirmModal
                show={showConfirmModal}
                onHide={onHideConfirmModal}
                onConfirm={onConfirm}
                onCancel={onCancel}
                title={confirmModalTitle}
                message={confirmModalText}
            />
            <Modal
                size={'xl'}
                show={show}
                onHide={onHide}
                centered={true}
                className={"modal-blur"}
                backdrop={"static"}
                keyboard={members?.length === 0}

            >
                <Modal.Body>
                    <div className="row">
                        <div className="d-flex col-lg-12">
                            <h3>
                                {t('Birthday Message')}
                            </h3>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onModalHide} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "left" }}>
                        <h3 style={{ marginRight: 20, alignContent: "center" }}>{t('Select the Type:')} </h3>
                        <label><span style={{ marginRight: 20, cursor: "pointer" }}>
                            <input style={{ display: "none", marginRight: 5 }} type="checkbox" id="email" name="email" value="Email" checked={email} onClick={(e) => onEmailClick(e, email)} />
                            <FaEnvelope color={email ? "#4299e1" : "grey"} size={30} title={"Email"} />
                        </span></label>
                        <label><span style={{ marginRight: 20, cursor: "pointer" }}>
                            <input style={{ display: "none", marginRight: 5 }} type="checkbox" id="sms" name="sms" value="SMS" checked={sms} onClick={(e) => onSmsClick(e, sms)} />
                            <FaRegMessage color={sms ? "#f59f00" : "grey"} size={30} title={"SMS"} />
                        </span></label>
                        <label><span style={{ marginRight: 20, cursor: "pointer" }}>
                            <input style={{ display: "none", marginRight: 5 }} type="checkbox" id="whatsapp" name="whatsapp" checked={whatsapp} value="whatsapp" onClick={(e) => onWhatsappClick(e, whatsapp)} />
                            <FaWhatsapp color={whatsapp ? "#0ca678" : "grey"} size={30} title={"Whatsapp"} />
                        </span></label>
                        <label>
                            <span style={{ marginRight: 20, cursor: "pointer" }}>
                                <input style={{ display: "none", marginRight: 5 }} type="checkbox" id="telegram" name="telegram" checked={telegram} value="telegram" onClick={(e) => onTelegramClick(e, telegram)} />
                                <FaTelegram color={telegram ? "#4299e1" : "grey"} size={30} title={"Telegram"} />
                            </span>
                        </label>
                    </div>
                    <div className="row">
                        <div className="d-flex col-lg-12">
                            <h3>
                                {t('To')}: {selectedbirthday.Names} {selectedbirthday.Surname}
                            </h3>
                            <p>{selectedbirthday.member_email}</p>
                            <p>{selectedbirthday.phone_cell}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mb-2">
                            <div className="form-group">
                                <label>{t('Message')}</label>
                                <input type="text" name="message"
                                    className="form-control"
                                    onChange={handleMessageChange} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <OverlayTrigger
                                trigger="click"
                                placement="top"
                                overlay={popover}
                                show={showPopover}
                                onHide={() => setShowPopover(false)}
                            >
                                <button className="btn rounded btn-outline-primary">
                                    <MdSend size={20} />
                                    <span className={"ms-2"} onClick={onMessageSent}>{t('Send')}</span>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}