import React, {useEffect, useRef, useState} from 'react';
import {usePermissions} from "../hooks/PermissionContext";

import {useLanguageStore} from "../hooks/useLanguage";
import {useAuth} from "../hooks/useAuth";
import {useTheme} from "../hooks/useTheme";
import toast from "react-hot-toast";

import {Button} from "@progress/kendo-react-buttons";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {GridPDFExport} from '@progress/kendo-react-pdf';
import {ExcelExport} from '@progress/kendo-react-excel-export';


import {getCookie} from "../func/Func";
import {ModalHeader} from "react-bootstrap";
import {Grid, GridColumn, GridToolbar, GridCellProps} from "@progress/kendo-react-grid";
import {Column} from "primereact/column";
import {FaFileExcel, FaFilePdf} from "react-icons/fa";

export const Reports = (props) => {
    const {t} = useLanguageStore();
    const {permissions} = usePermissions()
    const {user} = useAuth();
    const {theme} = useTheme();
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [showtranhistory, setShowtranhistory] = useState(false);


    useEffect(() => {
            getaccounts();
        }
        , []);

    const get_accounts_api = (company_id) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_accounts/`, {})
                .then(res => {
                    if (!res.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return res.json();
                })
                // .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data.data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    const getaccounts = () => {

        get_accounts_api()
            .then((data) => {
                setAccounts(data)
                console.log("accounts - data", data)
                console.log("accounts", accounts)

            })
            .catch((err) => {
                alert(err)
            })
    }

    const viewtransactionhistory = () => {
        console.log("selectedAccount", selectedAccount)
        setShowtranhistory(true)

    }

    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport !== null) {
            gridPDFExport.save();
        }
    };

    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const cellWithBackGround = (props) => {
        const {GridCellProps} = props;
        const exampleAmount = props.dataItem.amount < 0;


        const icon = exampleAmount ? (
            <span className="k-icon k-i-sort-desc-sm"/>
        ) : (
            <span className="k-icon k-i-sort-asc-sm"/>
        );

        const style = {
            color: exampleAmount ? 'rgb(243, 23, 0, 0.32)' : 'rgb(55, 180, 0,0.32)',

        };
        const field = props.field || '';
        return (
            <td style={style}>
                {props.dataItem[field]} {icon}
            </td>
        );
    };


    const grid = <ExcelExport data={selectedAccount?.transactions} ref={_export}><Grid
        data={selectedAccount?.transactions}>
        <GridToolbar>
            <Button title="Export PDF" themeColor={'primary'} type="button" onClick={exportPDF}>
                <FaFilePdf/>{t("Export to PDF")}
            </Button>
            <Button title="Export Excel"
                    themeColor={'success'}
                    type="button" onClick={excelExport}
                    cell={cellWithBackGround}
            >
               <FaFileExcel/> {t("Export to Excel")}
            </Button>
        </GridToolbar>
        <GridColumn title={selectedAccount?.transactions[0].bank_account_name}>
        <GridColumn field="transaction_date" title={t("Transaction Date")}/>
        <GridColumn field="company_name" title={t("Company Name")}/>
        <GridColumn field="description" title={t("Description")}/>
        <GridColumn
            title={t("Amount")}


        >
            <GridColumn field="income"
                        title={t("Income")}
                        cell={cellWithBackGround}
            />
            <GridColumn field="expense"
                        title={t("Expense")}
                        cell={cellWithBackGround}
            />
        </GridColumn>
        <GridColumn field="transaction_type_name" title={t("Type")}/>
        <GridColumn field="username" title={t("Username")}/>
        </GridColumn>
            {/*<GridColumn field="IncomeType" title={t("Income Type")}/>*/}

    </Grid>
    </ExcelExport>;


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-m-6 col-sm-3">
                        <div className="card-container">
                            <div className="card">

                                <ModalHeader>{t("Reports")}</ModalHeader>
                                <ComboBox
                                    data={accounts}
                                    textField="name"
                                    dataItemKey="id"
                                    placeholder="Select account"

                                    onChange={(e) => {
                                        console.log("e", e)
                                        setSelectedAccount(e.value)
                                    }}
                                />
                                {selectedAccount &&
                                    <Button
                                        onClick={viewtransactionhistory}
                                    >
                                        {t("View Transaction History")}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-m-12 col-sm-6">
                        <div className="card-container">
                            {showtranhistory &&
                                <div className="card">
                                    <ModalHeader>{t("Transaction History")}</ModalHeader>
                                    {grid}
                                    <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                                        {grid}
                                    </GridPDFExport>

                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}