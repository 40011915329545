import React, { useState, useEffect, useRef } from 'react';
import "./Styles/Groups.css";
import { getCookie } from "../func/Func";
import { AddParentGroup } from "../modals/AddParentGroup";
import toast from "react-hot-toast";
import { Button } from "@progress/kendo-react-buttons";
import { FaPlus, FaTimes, FaTrash, FaTrashAlt } from "react-icons/fa";
import { AddGroupMembers } from "../modals/AddGroupMembers";
import { usePermissions } from "../hooks/PermissionContext";
import { SubgroupModal } from '../modals/SubgroupModal';
import { ConfirmModal } from "../modals/ConfirmModal";
import { useLanguageStore } from "../hooks/useLanguage";


export const Groups = (props) => {
    const { t } = useLanguageStore();
    const permissions = usePermissions();
    const {
        theme,
        user
    } = props;
    const [groups, setGroups] = useState([]);
    const [showAddGroupModal, setShowAddGroupModal] = useState(false);
    const [showSubgroups, setShowSubgroups] = useState(false);
    const [parentGroupID, setParentGroupID] = useState(null);
    const [clickedGroupIndex, setClickedGroupIndex] = useState(null);
    const [newGroup, setNewGroup] = useState(undefined);
    const [viewMembers, setViewMembers] = useState(false);
    const [showGroupMembersModal, setShowGroupMembersModal] = useState(false);
    const [subGroupId, setSubGroupId] = useState(undefined);
    const [AllMembers, setAllMembers] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(undefined);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState(undefined);
    const [showAddMemberButton, setShowAddMemberButton] = useState(false);
    const [groupMembers, setGroupMembers] = useState([]);
    const [selectedSubGroup, setSelectedSubGroup] = useState(undefined);
    const [showSubGroupModal, setShowSubGroupModal] = useState(false);
    const debounceTimeout = useRef(null);
    const [search, setSearch] = useState("");
    const [members, setMembers] = useState([]);
    const [membersSearching, setMembersSearching] = useState(false);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState({});


    console.log("permissions in groups", permissions?.permissions);
    console.log("permissions in groups", permissions);

    const getGroupMembers = (group_id) => {
        setGroupMembers([]);
        fetch(`/apiv2/group_members/${group_id}/`).then(raw => raw.json()).then(data => {
            if (data.success) {
                setGroupMembers(data.data);
            }
        })
    }


    const handleShowAddGroupModal = (e, groupId) => {
        setParentGroupID(groupId);

        if (e.target.name === "AddSubGroup") {
            let newGroup = {
                name: e.target.value,
                company: user.company_id,
                parent_group_id: groupId,
                id: undefined
            }
            setNewGroup(newGroup);
        }

        setShowAddGroupModal(true);
    }
    const handleCloseAddGroupModal = () => {
        setShowAddGroupModal(false);
    }


    useEffect(() => {
        getGroups()
        setShowAddGroupModal(false);
        setViewMembers(false);
        setShowSubgroups(false);
    }, []);

    const SubgroupCount = (props) => {
        const { group } = props;
        let text = "";
        let number = groups.filter((g) => g.parent_group_id === group.id).length;
        return (
            <span className='badge badge-pill bg-info-lt ms-2'>
                {groups.filter((g) => g.parent_group_id === group.id).length} {(groups.filter((g) => g.parent_group_id === group.id).length) > 1 ? t("subgroups") : t("subgroup")}
            </span>
        )
    }


    const get_groups_api = (company_id) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_groups`, {})
                .then(res => {
                    if (!res.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return res.json();
                })
                // .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data.data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    const getGroups = () => {

        get_groups_api()
            .then((data) => {
                setGroups(data)
            })
            .catch((err) => {
                alert(err)
            })
    }

    const addGroup = (group) => {
        fetch(`/apiv2/api_add_group/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify(group)
        }).then(res => res.json())
            .then(data => {
                if (data?.success) {
                    setShowAddGroupModal(false);
                    getGroups();
                    toast.success(t("Group added successfully"));
                } else {
                    toast.error(data?.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const deleteGroup = (e, group) => {
        e.preventDefault();
        let groupid = group.id;

        api_delete_group(groupid)
            .then((data) => {
                toast.success(t("Group deleted successfully"))
                getGroups()
            })
            .catch((err) => {
                toast.error(err)
            })

    }

    const api_delete_group = (group_id) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/api_delete_group/${group_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                    'data': { group_id: group_id }
                }
            }).then(res => res.json())
                .then(data => {
                    if (data?.success) {
                        resolve(data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }


    const handleShowSubgroups = (index) => {
        if (clickedGroupIndex === index) {
            setShowSubgroups(!showSubgroups);
        } else {
            setClickedGroupIndex(index);
            setShowSubgroups(true);
        }
    }

    const searchChanged = (e) => {
        const value = e.target.value;
        setSearch(value);

        // Clear the previous timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to call the search function after 1000ms
        debounceTimeout.current = setTimeout(() => {
            memberQuickSearch(value);
        }, 1000);
    }

    const memberQuickSearch = (value) => {
        setMembers([]);
        if (value?.length >= 3) {
            setMembersSearching(true);
            fetch(`/apiv2/member_quick_search/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                },
                body: JSON.stringify({ phrase: value })
            }).then(res => res.json()).then(data => {
                setMembers(data);
                setMembersSearching(false);
            }).catch(err => {
                setMembers([]);
                setMembersSearching(false);
            })
        }

    }

    const handleViewMembers = (e, subgroup) => {
        getGroups();
        setSelectedGroup(subgroup);
        setSelectedGroupMembers(subgroup.users);
        setViewMembers(!viewMembers);
        setShowAddMemberButton(!showAddMemberButton);
    }

    const handleCloseGroupMembersModal = () => {
        setViewMembers(false);
        setShowAddMemberButton(false);
        setShowGroupMembersModal(false);
    }

    const handleShowGroupMembersModal = (e, subgroup) => {
        setViewMembers(true);
        setShowGroupMembersModal(true);
        setSelectedGroup(subgroup);
        setSelectedGroupMembers(subgroup.users);
        setSubGroupId(subgroup.id);
    }

    const groupClicked = (e, group) => {
        e.preventDefault();
        if (selectedGroup?.id !== group.id) {
            setSelectedGroup(group);
        } else {
            setSelectedGroup(undefined);
        }
        setSelectedSubGroup(undefined);
    }



    const subgroupClicked = (e, subgroup) => {
        e.preventDefault();
        e.stopPropagation();
        getGroupMembers(subgroup?.id);
        setSelectedSubGroup(subgroup);
        setShowSubGroupModal(true);
        setMembers([]);
        setSearch("");
    }

    const SubGroups = (props) => {
        const group = props.group;
        return (
            groups?.map((subgroup, subIndex) => {
                if (subgroup?.parent_group_id === group?.id && selectedGroup?.id === group?.id) {
                    return (
                        <tr onClick={e => subgroupClicked(e, subgroup)} key={subIndex} className={`bg-primary-lt cursor-pointer ${theme === 'dark' && 'text-light'}`}>
                            <td className='d-flex justify-content-start'>
                                <span className='ps-4'>
                                    {subgroup?.name}
                                </span>
                                <span className='badge badge-pill bg-info-lt ms-2'>
                                    {subgroup?.users?.length} {subgroup?.users?.length > 1 ? t("members") : t("member")}
                                </span>
                                {/* <span onClick={e => viewMembersClicked(e,subgroup)} className='badge py-2 badge-pill bg-primary ms-2 text-white'>
                                    {t("View Members")}
                                </span> */}
                            </td>
                            <td></td>
                        </tr>
                    )
                }
            })
        )
    }

    const deleteGroupClicked = (e, group) => {
        e.preventDefault();
        e.stopPropagation();
        setConfirmModalData({
            title: t("Delete Group"),
            message: t("Are you sure you want to delete this group?"),
            onConfirm: () => {
                deleteGroup(e, group);
                setShowConfirmModal(false);
            },
            onCancel: () => {
                setShowConfirmModal(false);
            },
            onHide: () => {
                setShowConfirmModal(false);
            }
        });
        setShowConfirmModal(true);
    }

    return (
        <div>
            <ConfirmModal
                show={showConfirmModal}
                onHide={confirmModalData?.onHide}
                title={confirmModalData?.title}
                message={confirmModalData?.message}
                onConfirm={confirmModalData?.onConfirm}
                onCancel={confirmModalData?.onCancel}
            />
            <SubgroupModal
                show={showSubGroupModal}
                onHide={() => setShowSubGroupModal(false)}
                theme={theme}
                user={user}
                group={selectedSubGroup}
                groupMembers={groupMembers}
                searchChanged={searchChanged}
                search={search}
                members={members}
                membersSearching={membersSearching}
            />
            {viewMembers && permissions?.permissions?.includes("modify_groups") &&
                (<AddGroupMembers
                    show={showGroupMembersModal}
                    onShow={handleShowGroupMembersModal}
                    onHide={handleCloseGroupMembersModal}
                    subgroupid={subGroupId}
                    setsubgroupid={setSubGroupId}
                    user={user}
                    setAllMembers={setAllMembers}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    AllMembers={AllMembers}
                    selectedGroupMembers={selectedGroupMembers}
                />)}




            <div className="row">
                <div className="col-12 d-flex">
                    <h1>{t("Groups")}</h1>
                    <span name="AddGroup" onClick={(e) => handleShowAddGroupModal(e)} class="badge badge-pill ms-2 h4 d-flex bg-primary-lt justify-items-center align-items-center cursor-pointer w-fit-content">{t("Add group")}</span>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 col-sm-12 col-md-6">
                    <div className="card table-responsive mb-2">
                        <table className="table table-hover">
                            <tbody>
                                {groups?.map((group, groupIndex) => {
                                    if (!group?.parent_group_id) {
                                        let className = selectedGroup?.id === group?.id && `bg-primary ${theme === "light" && "text-white"}`
                                        return (
                                            <>
                                                <tr className={`${className} cursor-pointer`} key={groupIndex} onClick={e => groupClicked(e, group)}>
                                                    <td>{group?.name} <SubgroupCount group={group} /></td>
                                                    <td className='text-end'>
                                                        <span className='text-danger'>

                                                            <FaTrash size={20} className={"ms-auto cursor-pointer"}
                                                                onClick={(e) => deleteGroupClicked(e, group)} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <SubGroups group={group} />
                                            </>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <hr />
                </div>
            </div>


            {groups.length > 0 ? (
                <table className="table-container">
                    <thead>
                        <tr>
                            <th className="table-header"><h1>{t("Groups")}</h1></th>
                        </tr>
                    </thead>
                    <tbody>
                        {groups.filter(group => !group.parent_group_id).map((group, index) => (
                            // let subgroup === null

                            <React.Fragment key={index}>
                                <tr className={`main-group-row ${showSubgroups && clickedGroupIndex !== index ? 'blurred' : ''}`}>
                                    <td className="main-group-cell"
                                        onClick={() => handleShowSubgroups(index)}
                                        title={t("Click to view subgroups")}
                                    ><h2>{group.name}</h2>
                                    </td>
                                    {permissions?.permissions?.includes("modify_groups") &&
                                        <td>
                                            <FaTrash size={20} className={"ms-auto cursor-pointer"} color="orangeRed"
                                                onClick={(e) => deleteGroup(e, group)} />
                                        </td>}
                                </tr>
                                {showSubgroups && clickedGroupIndex === index && (
                                    // when this is visible the background must be a bit blurry
                                    <tr className="subgroup-container">
                                        {groups.filter((g) => g.parent_group_id === group.id).length > 0 ? (
                                            <>
                                                <td colSpan="2">
                                                    {groups.filter((g) => g.parent_group_id === group.id).map((subgroup, subIndex) => (
                                                        <div key={subIndex} className="subgroup-row">

                                                            <div className="subgroup-cell"><h2>{subgroup.name}</h2></div>
                                                            <div className="button-container">
                                                                <Button
                                                                    onClick={(e) => handleViewMembers(e, subgroup)}>
                                                                    {viewMembers && selectedGroup?.id === subgroup.id ? (t("Hide Members")) : (t("View Members"))}</Button>
                                                                {showAddMemberButton && selectedGroup?.id === subgroup.id &&

                                                                    <Button
                                                                        disabled={!permissions?.permissions?.includes("modify_groups")}
                                                                        onClick={(e) => handleShowGroupMembersModal(e, subgroup)}>
                                                                        {t("Edit Members")}
                                                                    </Button>
                                                                }
                                                                {showAddMemberButton && selectedGroup?.id === subgroup.id &&
                                                                    <FaTrash size={20}
                                                                        className={"ms-auto cursor-pointer "}
                                                                        style={{
                                                                            color: "lightgray",
                                                                            padding: "2px",

                                                                        }}

                                                                        onClick={(e) => deleteGroup(e, subgroup)} />}
                                                            </div>

                                                            {viewMembers && selectedGroup?.id === subgroup.id &&

                                                                (<div className="view-members-container">

                                                                    {subgroup.users.length > 0 && subgroup.users.map((userId, index) => {
                                                                        const user = AllMembers.find((m) => m.id === userId);
                                                                        return user &&

                                                                            <div key={index} className="member-row">
                                                                                <h3> {user.FullNames} {user.Surname}</h3>
                                                                            </div>


                                                                    })}
                                                                    {subgroup.users.length === 0 &&
                                                                        <div className="member-row">
                                                                            <h2> {t("No members")}</h2>
                                                                        </div>}
                                                                </div>)}
                                                        </div>


                                                    ))}
                                                    <div className="button-container">
                                                        <Button name="AddSubGroup"
                                                            className="add-subgroup-button"
                                                            size={"small"}
                                                            onClick={(e) => handleShowAddGroupModal(e, group.id)}>
                                                            <FaPlus />{t("Add Subgroup")}
                                                        </Button>
                                                    </div>

                                                </td>
                                            </>

                                        ) : (
                                            <td style={{ margin: '2px' }}>
                                                <h1>No subgroups</h1>
                                                <Button name="AddSubGroup"

                                                    onClick={(e) => handleShowAddGroupModal(e, group.id)}>{t("Add Subgroup")}</Button>
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </React.Fragment>
                        )
                        )}
                    </tbody>
                </table>
            ) :
                (
                    <p>{t("No groups")}</p>
                )
            }
            {
                !showSubgroups &&
                <div style={{ margin: "10px", padding: "10px" }}>
                    <Button
                        name="AddGroup"
                        onClick={(e) => handleShowAddGroupModal(e)}>
                        {t("Add Group")}
                    </Button>
                </div>
            }
            {
                showAddGroupModal && <AddParentGroup
                    showAddGroupModal={showAddGroupModal}
                    setShowAddGroupModal={setShowAddGroupModal}
                    onShow={handleShowAddGroupModal}
                    onHide={handleCloseAddGroupModal}
                    user={user}
                    groups={groups}
                    setGroups={setGroups}
                    addGroup={addGroup}
                    parentGroupID={parentGroupID}
                    parentGroup={groups[parentGroupID]}
                />
            }
        </div>
    )
        ;
}
    ;

export default Groups;
