import React, {useEffect, useState} from 'react';
import { useLanguageStore } from "../hooks/useLanguage";
import Modal from 'react-bootstrap/Modal';
import {FaArrowDown, FaArrowUp, FaPaperPlane, FaSave, FaTimes, FaUserPlus, FaSearch} from "react-icons/fa";
import {BsSearch} from "react-icons/bs";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";
import {ConfirmModal} from "./ConfirmModal";
import {FamilyMemberModal} from "./FamilyMemberModal";
import {IconArrowUp, IconArrowDown} from "@tabler/icons-react";
import Dropdown from 'react-bootstrap/Dropdown';
import {BirthdayMessageModal} from "./BirthdayMessageModal";
import Popover from 'react-bootstrap/Popover';

export const AddGroupMembers = (props) => {
    const { t } = useLanguageStore();
    const {
        show,
        onShow,
        onHide,
        subgroupid,
        setsubgroupid,
        user,
        setAllMembers,
        selectedGroup,
        setSelectedGroup,
        AllMembers,
        selectedGroupMembers

    } = props;

    const [groupMembers, setgroupMembers] = useState([]);

    const [newGroupMembersSaved, setNewGroupMembersSaved] = useState(false);
    const [searchItem, setSearchItem] = useState("");
    const [filteredGroupMembers, setFilteredGroupMembers] = useState(groupMembers);


    const handleInputChange = (e) => {
        const searchItem = e.target.value;
        setSearchItem(searchItem)

        const filteredItems = groupMembers.filter((member) => {
            if (member?.Names !== null) {
                return member.Names.toLowerCase().includes(searchItem.toLowerCase());
            }
            return false;
        });
        setFilteredGroupMembers(filteredItems);
    };

    useEffect(() => {
        if (selectedGroup && selectedGroupMembers) {
            const fullUsers = selectedGroupMembers.map(userId => {
                return AllMembers.find(member => member.id === userId);
            }).filter(user => user !== undefined);

            setSelectedGroup(prevState => ({
                ...prevState,
                users: fullUsers
            }));
        }
        getGroupMembers();
    }, [subgroupid]);


    const getGroupMembers_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_new_attendees/`, {})
                .then(res => res.json())
                // .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data.data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
    const getGroupMembers = () => {

        getGroupMembers_api()
            .then((data) => {
                toast.custom("Group Members Loaded Successfully")
                setgroupMembers(data)
                setAllMembers(data)
                setFilteredGroupMembers(data)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const updateGroupMembers_api = () => {
        return new Promise((resolve, reject) => {
            let url = `/apiv2/update_group_members/`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),

                },
                body: JSON.stringify({
                    group: selectedGroup,
                }),
            })
            .then(response => {
            if (!response.ok) {
                return response.text().then(text => { throw new Error(text) });
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                resolve(data);
            } else {
                reject(data.message);
            }
        })
        .catch(err => {
            reject(err.message || err);
        });
    });
}
    const updateGroupMembers = () => {

        updateGroupMembers_api()
            .then(data => {
                toast.custom(t("GroupMembers updated Successfully"))
                onHide();
            }).catch(err => {
                toast.error(t("GroupMembers updated Unsuccessful"))
            }
        )
        setNewGroupMembersSaved(true)
    }


    const onModalHide = () => {
            onHide();
        }


    const AddGroupMember = (e, grpmember) => {
        // Create a new array with the added member
        const newUsers = [...selectedGroup.users, grpmember];

        // Update the selectedGroup with the new users array
        const newGroup = {...selectedGroup, users: newUsers};

        // Filter out the added member from the groupMembers list
        const newGroupMembers = groupMembers.filter((mem) => mem.id !== grpmember.id);

        setgroupMembers(newGroupMembers);
        setSelectedGroup(newGroup);
    };

    const RemoveGroupMember = (e, participant) => {

        // Create a new array without the removed member
        const newUsers = selectedGroup.users.filter((user) => user.id !== participant.id);

        // Update the selectedGroup with the new users array
        const newGroup = {...selectedGroup, users: newUsers};

        // Add the removed member back to the groupMembers list
        const newGroupMembers = [...groupMembers, participant];

        setgroupMembers(newGroupMembers);
        setSelectedGroup(newGroup);
    };
    return (
        <div>
            <Modal
                size={'md'}
                show={show}
                onHide={onHide}
                centered={true}
                className={"modal-blur "}
                backdrop={"static"}

            >
                <Modal.Body style={{maxHeight: 500, overflowY: "scroll"}}>
                    <div className="row ">
                        <div className="d-flex col-lg-12" style={{justifyContent: "space-between"}}>
                            <h2>
                                {t('Current Group Members', {
                                    description: selectedGroup?.description,
                                })}
                            </h2>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onModalHide}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ol style={{listStyleType: "none"}}>
                                {selectedGroup?.users?.map((mem, index) => {
                                    const user = AllMembers.find((member) => member.id === mem.id);
                                    return user && (

                                        <li key={index}
                                            style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : ''}}>
                                            <FaArrowDown
                                                style={{marginLeft: "10px", cursor: "pointer", color: "orangered"}}
                                                tooltip={"Remove Member"}
                                                onClick={(e) => RemoveGroupMember(e, mem)}

                                            />
                                            {mem.Names} {mem.Surname}</li>
                                    )
                                })}
                            </ol>
                            <span style={{display: "flex", justifyContent: "space-between"}}>
                                <button className="btn rounded btn-outline-primary d-flex position: absolute;
                                              right: 0;
                                              top: 50%;
                                              transform: translateY(-50%);
                                            " style={{marginBottom: 20}} onClick={updateGroupMembers}>
                                    <FaSave size={20}/>
                                    <span className={"ms-2"}>{t('Save Members')}</span>
                                </button>
                            </span>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h2>{t("Select Members")}</h2>
                                <div>
                                    <BsSearch
                                        style={{
                                            marginRight: "10px",
                                            cursor: "pointer",
                                            color: "limegreen",
                                            fontSize: 20
                                        }}
                                    />
                                    <input type="text"
                                           value={searchItem}
                                           onChange={handleInputChange}
                                           placeholder={t("Type to search...")}
                                    >

                                    </input>
                                </div>
                            </div>
                            {filteredGroupMembers.length === 0 ? <p>Not Found</p>
                                : <ol style={{listStyleType: "none"}}>
                                    {filteredGroupMembers?.map((grpmember, index) => {
                                        return (
                                            <li key={index}
                                                style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : ''}}>
                                                <FaArrowUp
                                                    style={{marginLeft: "10px", cursor: "pointer", color: "limegreen"}}
                                                    onClick={(e) => {
                                                        AddGroupMember(e, grpmember);
                                                    }}
                                                    tooltip={t("Add Group Member")}
                                                />
                                                {grpmember.Names}
                                                {grpmember.Surname}
                                            </li>

                                        );
                                    })}
                                </ol>}

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
        ;
}