import React, { useEffect, useState } from 'react';
import { useEnv } from "../hooks/useEnv";
import { useAuth } from "../hooks/useAuth";
import { useLanguageStore } from "../hooks/useLanguage";
import { useWebsocket } from "../hooks/useWebsocket";
import { FaUsers, FaAt, FaEnvelope, FaTelegram, FaMessage, FaRegMessage, FaWhatsapp } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import BirthdayReminder from "./BirthdayReminder";
import UpComingEvents from "./UpComingEvents";
import i18n from 'i18next';
import LanguageSwitcher from "../func/LanguageSwitcher";
import { usePermissions } from "../hooks/PermissionContext";


export const Home = (props) => {
    const permissions = usePermissions();
    const { t } = useLanguageStore();
    const navigate = useNavigate();
    const { user, fetchPermissions } = useAuth();
    const [stats, setStats] = useState({});
    const [language, setLanguage] = useState('en');


    useEffect(() => {
        // initWebsocket();
        getStats();
    }, []);

    const getStats = () => {
        fetch(`/apiv2/stats/`, {
            method: 'GET',
        }).then(res => res.json())
            .then(data => {
                if (data?.success) {
                    setStats(data?.data);

                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const gotoMembers = () => {
        navigate('/members');
    }

    return (
        <>
            <div className="container-fluid">
                <h1 className={"text-center text-muted mb-1"}>{stats?.company?.name}</h1>
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <span className={"badge badge-pill mb-0 h4 d-flex bg-primary-lt justify-items-center align-items-center cursor-pointer w-fit-content cursor-pointer"} onClick={gotoMembers}>{stats?.total_members} {t("Total Members")}</span>
                </div>
                <div className="row">
                    {permissions?.permissions?.includes('send_messages') &&
                        <>

                            <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
                                <div className="card p-3 cursor-pointer">
                                    <div className="row">
                                        <div className="col-6 d-flex justify-content-center align-items-center">
                                            <FaEnvelope color={"#0ca678"} size={60} />
                                            {/*<FaAt color={"#4299e1"} size={60}/>*/}
                                        </div>
                                        <div className="col-6">
                                            <h2 className={"mt-2 mb-0"}>{stats?.company?.email_credits}</h2>
                                            <h2 className={"mb-2 mt-0"}>{t('Email Credits')}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
                                <div className="card p-3 cursor-pointer">
                                    <div className="row">
                                        <div className="col-6 d-flex justify-content-center align-items-center">
                                            <FaRegMessage color={"#f59f00"} size={60} />
                                        </div>
                                        <div className="col-6">
                                            <h2 className={"mt-2 mb-0"}>{stats?.company?.sms_credits}</h2>
                                            <h2 className={"mb-2 mt-0"}>{t('SMS Credits')}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
                                <div className="card p-3 cursor-pointer">
                                    <div className="row">
                                        <div className="col-6 d-flex justify-content-center align-items-center">
                                            <FaTelegram color={"#4299e1"} size={60} />
                                        </div>
                                        <div className="col-6">
                                            <h2 className={"mt-2 mb-0"}>{stats?.company?.telegram_credits}</h2>
                                            <h2 className={"mb-2 mt-0"}>{t('Telegram Credits')}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-lg-6 col-sm-12 col-md-6 mb-3">
                                <div className="card p-3 cursor-pointer">
                                    <div className="row">
                                        <div className="col-6 d-flex justify-content-center align-items-center">
                                            <FaWhatsapp color={"#0ca678"} size={60} />
                                            {/*<FaAt color={"#4299e1"} size={60}/>*/}
                                        </div>
                                        <div className="col-6">
                                            <h2 className={"mt-2 mb-0"}>{stats?.company?.whatsapp_credits}</h2>
                                            <h2 className={"mb-2 mt-0"}>{t('WhatsApp Credits')}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className='row'>

                    {
                        permissions?.permissions?.includes('view_members') &&
                        <div className="col-lg-6 col-sm-12 col-md-12 mb-3">
                            <div className="card card-table p-3 cursor-pointer mx-0 px-0 pb-0 mb-0">
                                {/* <div className="row"> */}
                                {/* <div> */}
                                <BirthdayReminder color={"#4299e1"} size={60} user={user} />
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    }


                    {
                        permissions?.permissions?.includes('view_events') &&

                        <div className="col-lg-6 col-sm-12 col-md-12 mb-3">
                            <div className="card card-table p-3 cursor-pointer mx-0 px-0 pb-0 mb-0">
                                {/* <div className="row"> */}
                                {/* <div> */}
                                <UpComingEvents color={"#4299e1"} size={60} user={user} />
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    }


                    {/*<div className="col-lg-3 col-sm-6 col-md-6 mb-3">*/
                    }
                    {/*  <div className="card p-3 d-flex align-content-center justify-content-center">*/
                    }
                    {/*    <FaEnvelope className={"m-auto"} size={40}/>*/
                    }
                    {/*    <h2 className={"text-center mb-0 mt-2"}>{stats?.company?.sms_credits}</h2>*/
                    }
                    {/*    <h2 className={"text-center mb-2 mt-2"}>SMS Credits</h2>*/
                    }
                    {/*  </div>*/
                    }
                    {/*</div>*/
                    }

                </div>
            </div>
        </>
    )
}