import { create } from "zustand";
import translations from "../translations/translations.json";


export const languages = {
  en: {
    value: "en",
    symbol: "🇬🇧",
    label: "English",
    flag: "en",
  },
  afr: {
    value: "afr",
    symbol: "🇿🇦",
    label: "Afrikaans",
    flag: "afr",
  },
};

// Replace placeholder function
const replacePlaceholders = (str, params) => {
  return str.replace(/\${(\w+)}/g, (_, key) => params[key] || `\${${key}}`);
};

// Create Zustand store
export const useLanguageStore = create((set, get) => ({
  language: localStorage.getItem("language")
    ? JSON.parse(localStorage.getItem("language"))
    : languages.en, // Default to English

  setLanguage: (newLanguage) => {
    set({ language: newLanguage });
    localStorage.setItem("language", JSON.stringify(newLanguage));
  },

  t: (phrase, params) => {
    const language = get().language;
    const translated = translations[phrase]?.[language.value] || phrase;
    if (!translations[phrase]) {
      let missingPhrases = localStorage.getItem("missing-translations") ? JSON.parse(localStorage.getItem("missing-translations")) : {};
      missingPhrases[phrase] = {
        "afr": ""
      };
      localStorage.setItem("missing-translations", JSON.stringify(missingPhrases));
    }
    return replacePlaceholders(translated, params);
  },

  languages: languages, // Expose available languages
}));
