import i18n from 'i18next';
import React from 'react';
import {Button} from "@progress/kendo-react-buttons"
import { useLanguageStore } from "../hooks/useLanguage";

const LanguageSwitcher = ({ language, theme }) => {
  const { setLanguage, languages } = useLanguageStore();

  return (
    <div className='d-flex align-items-center'>
      {Object.values(languages).map(language => {
        return (
          <span key={language.value} className={`${theme == "dark" ? 'bg-primary-lt text-light' : 'bg-primary text-light'} rounded cursor-pointer p-1 px-2 me-2`} onClick={() => setLanguage(language)}>{language.symbol} {language.label}</span>
        )
      })}
    </div>
  );
};

export default LanguageSwitcher;