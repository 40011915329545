import React, {useEffect, useState} from 'react';
import { useLanguageStore } from "../hooks/useLanguage";

export const NotFound = (props) => {
    const { t } = useLanguageStore();
    return (
        <>
            <div className="container-fluid">
                <h1>{t("Page not found")}</h1>
            </div>
        </>
    )
}